import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import PageBody from '../components/PageBody';

const PageTemplate = ({ data, location }) => {
  const {
    prismicPage: { data: page },
  } = data;

  if (!page) return null;

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    social_card: { localFile: socialCardImage },
  } = page;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={metaTitle || page.title.text}
        description={metaDescription}
        image={socialCardImage ? socialCardImage.url : undefined}
      />
      <PageBody document={page} />
    </>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          html
          text
        }
        meta_title
        meta_description
        social_card {
          alt
          localFile {
            url
          }
        }
        body {
          ... on PrismicPageBodyCta {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              subheading
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicPageBodyHero {
            id
            slice_type
            primary {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              align
              heading {
                html
              }
              pre_heading
              subheading
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyImageCta {
            id
            slice_type
            primary {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              align
              heading {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyGridNav {
            id
            slice_type
            items {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              heading {
                html
              }
              subheading
            }
          }
          ... on PrismicPageBodyMetrics {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              metric
              value
            }
          }
          ... on PrismicPageBodyLogosStrip {
            id
            slice_type
            items {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTextBlock {
            id
            slice_type
            primary {
              heading {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicPageBodyActionBar {
            id
            slice_type
            primary {
              label
              action_label
              template
              action_url {
                url
                uid
                type
                target
              }
              person {
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      name
                      title
                      photo {
                        localFile {
                          childImageSharp {
                            fixed(width: 60, height: 60) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      photoBig: photo {
                        localFile {
                          childImageSharp {
                            fixed(width: 80, height: 80) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyMap {
            id
            slice_type
            items {
              name
              country
              city
              address
              post_code
              email
              location {
                latitude
                longitude
              }
            }
          }
          ... on PrismicPageBodyContactForm {
            id
            slice_type
            primary {
              heading {
                html
              }
              subheading
              action_label
            }
          }
          ... on PrismicPageBodyTeam {
            id
            slice_type
            primary {
              heading {
                html
              }
              size
            }
            items {
              person {
                uid
                type
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      name
                      title
                      bio {
                        html
                      }
                      linkedin {
                        url
                        uid
                        type
                        target
                      }
                      photo {
                        localFile {
                          childImageSharp {
                            fixed(width: 128, height: 128) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      photoBig: photo {
                        localFile {
                          childImageSharp {
                            fixed(width: 176, height: 176) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
